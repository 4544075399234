import React, {useEffect, useState} from "react";


export default ({className, style, onClick1, onClick2}) => {
  const [translates, setTranslates] = useState({})
  const interval = 5000
  useEffect(() => {
    const range = 50.0
    let counter = 0
    const updateTranslates = () => {
      let translates = {}
      for (let i = 0; i < 10; i++) {
        if (counter % 2 === 0) {
          if (i < 7) {
            translates[`x${i}`] = -Math.random() * range + 50
            translates[`y${i}`] = -Math.sin(Math.PI / 3) * translates[`x${i}`]
          } else {
            translates[`x${i}`] = Math.random() * range / 2
            translates[`y${i}`] = -Math.sin(Math.PI / 3) * translates[`x${i}`]
          }
        } else {
          if (i < 7) {
            translates[`x${i}`] = 0
            translates[`y${i}`] = 0
          } else {
            translates[`x${i}`] = 0
            translates[`y${i}`] = 0
          }
        }
      }
      counter += 1
      setTranslates(translates)
    }
    updateTranslates()
    const cancelInterval = setInterval(() => {
      updateTranslates()
    }, interval)
    return () => {
      clearInterval(cancelInterval)
    }
  }, [])
  return <svg
    className={className}
    style={style}
    viewBox="-172 351 815 1500" version="1.1" xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter id="star04Shadow" x="0" y="0" width="200%" height="200%">
        <feOffset result="offOut" in="SourceGraphic" dx="5" dy="5" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
      <radialGradient cx="50.2294385%" cy="49.4516823%" fx="50.2294385%" fy="49.4516823%" r="50.0668293%"
                      id="star04RadialGradient-1">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3331459%" cy="49.6326392%" fx="50.3331459%" fy="49.6326392%" r="50.0652632%"
                      id="star04RadialGradient-2">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.2260704%" cy="49.5123622%" fx="50.2260704%" fy="49.5123622%" r="50.0636842%"
                      id="star04RadialGradient-3">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.2968639%" cy="49.6342778%" fx="50.2968639%" fy="49.6342778%" r="50.0642105%"
                      id="star04RadialGradient-4">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.2534904%" cy="49.5495599%" fx="50.2534904%" fy="49.5495599%" r="50.0614634%"
                      id="star04RadialGradient-5">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3577846%" cy="49.491388%" fx="50.3577846%" fy="49.491388%" r="50.06%"
                      id="star04RadialGradient-6">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.241521%" cy="49.6380153%" fx="50.241521%" fy="49.6380153%" r="50.0619298%"
                      id="star04RadialGradient-7">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.2513795%" cy="49.4784421%" fx="50.2513795%" fy="49.4784421%" r="50.0140351%"
                      id="star04RadialGradient-8">
        <stop stopColor="#FFFFFF" offset="3.479043%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
      </radialGradient>
      <radialGradient cx="50.3048496%" cy="49.5001949%" fx="50.3048496%" fy="49.5001949%" r="50.0140351%"
                      id="star04RadialGradient-9">
        <stop stopColor="#FFFFFF" offset="3.479043%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
      </radialGradient>
      <radialGradient cx="50.2416879%" cy="49.574073%" fx="50.2416879%" fy="49.574073%" r="50.0140351%"
                      id="star04RadialGradient-10">
        <stop stopColor="#FFFFFF" offset="3.479043%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
      </radialGradient>
      <radialGradient cx="50.3048232%" cy="49.5054983%" fx="50.3048232%" fy="49.5054983%" r="50.0140351%"
                      id="star04RadialGradient-11">
        <stop stopColor="#FFFFFF" offset="3.479043%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
      </radialGradient>
      <radialGradient cx="50.2735832%" cy="49.6025396%" fx="50.2735832%" fy="49.6025396%" r="50.0140351%"
                      id="star04RadialGradient-12">
        <stop stopColor="#FFFFFF" offset="3.479043%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
      </radialGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
       transform="translate(-172.000000, 351.000000)">
      <rect  x="0" y="0" width="815" height="1500"/>
      <circle
        onClick={onClick1}
        style={{
          transform: `translate(${translates.x0}px, ${translates.y0}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-1)" cx="341.6" cy="558.5" r="20.5">
        <animate attributeName="r" begin="0s" dur="30s" repeatCount="indefinite" values="10;60;20;60.5;20;60;40;20;10"/>
      </circle>
      <circle
        onClick={onClick1}
        style={{
          transform: `translate(${translates.x1}px, ${translates.y1}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-2)" cx="425.2" cy="549.7" r="28.5">
        <animate attributeName="r" begin="2s" dur="12s" repeatCount="indefinite" values="10;60;20;60.5;20;60;40;20;10"/>
      </circle>
      <circle
        onClick={onClick1}
        style={{
          transform: `translate(${translates.x2}px, ${translates.y2}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-3)" cx="418.2" cy="498.2" r="28.5">
        <animate attributeName="r" begin="3s" dur="8s" repeatCount="indefinite" values="10;80;20;80.5;20;80;40;20;10"/>
      </circle>
      <circle
        onClick={onClick1}
        style={{
          transform: `translate(${translates.x3}px, ${translates.y3}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-4)" cx="399.4" cy="517.3" r="28.5">
        <animate attributeName="r" begin="3s" dur="10s" repeatCount="indefinite" values="10;80;20;80.5;20;80;40;20;10"/>
      </circle>
      <circle
        onClick={onClick1}
        style={{
          transform: `translate(${translates.x4}px, ${translates.y4}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-5)" cx="351.8" cy="624" r="20.5">
        <animate attributeName="r" begin="1s" dur="12s" repeatCount="indefinite" values="10;80;20;80.5;20;80;40;20;10"/>
      </circle>
      <circle
        onClick={onClick1}
        style={{
          transform: `translate(${translates.x5}px, ${translates.y5}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-6)" cx="285.4" cy="660.5" r="20.5">
        <animate attributeName="r" begin="5s" dur="8s" repeatCount="indefinite" values="10;80;20;80.5;20;80;40;20;10"/>
      </circle>
      <circle
        onClick={onClick1}
        style={{
          transform: `translate(${translates.x6}px, ${translates.y6}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-7)" cx="376.2" cy="484.9" r="28.5">
        <animate attributeName="r" begin="0s" dur="5s" repeatCount="indefinite" values="10;80;20;80.5;20;80;40;20;10"/>
      </circle>


      <circle
        onClick={onClick2}
        style={{
          transform: `translate(${translates.x7}px, ${translates.y7}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-8)" cx="568.6" cy="1112.5" r="28.5">
        <animate attributeName="r" begin="0s" dur="10s" repeatCount="indefinite" values="10;80;20;60.5;20;80;40;20;10"/>
      </circle>
      <circle
        onClick={onClick2}
        style={{
          transform: `translate(${translates.x8}px, ${translates.y8}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-9)" cx="550.2" cy="1176.3" r="28.5">
        <animate attributeName="r" begin="1s" dur="15s" repeatCount="indefinite" values="10;80;20;60.5;20;80;40;20;10"/>
      </circle>
      <circle
        onClick={onClick2}
        style={{
          transform: `translate(${translates.x9}px, ${translates.y9}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-10)" cx="524.1" cy="1047.3" r="28.5">
        <animate attributeName="r" begin="5s" dur="15s" repeatCount="indefinite" values="10;60;20;80.5;20;80;40;20;10"/>
      </circle>
      <circle
        onClick={onClick2}
        style={{
          transform: `translate(${translates.x10}px, ${translates.y10}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-11)" cx="538.5" cy="964.7" r="28.5">
        <animate attributeName="r" begin="0s" dur="12s" repeatCount="indefinite" values="10;80;20;80.5;20;60;40;20;10"/>
      </circle>
      <circle
        onClick={onClick2}
        style={{
          transform: `translate(${translates.x11}px, ${translates.y11}px)`,
          transition: `transform ${interval}ms ease-in-out`
        }}
        fill="url(#star04RadialGradient-12)" cx="567" cy="1017.8" r="28.5">
        <animate attributeName="r" begin="0s" dur="15s" repeatCount="indefinite" values="10;80;20;80.5;20;60;40;20;10"/>
      </circle>

      <g className={"Tips"}
         onClick={onClick1}
         filter={"url(#star04Shadow)"}
      >
        <path d="M467.1,699.1 C467.1,700 466.6,700.7 465.6,701.1 C463,702.2 460.4,703.2 457.8,704.3 C455.9,705.1 454,705.8 452.1,706.6 C451.8,706.7 451.6,706.9 451.5,707.2 C449.7,711.7 447.9,716.2 446,720.7 C445.8,721.2 445.5,721.7 445,722 C444,722.5 442.8,722.2 442.2,721.3 C442.1,721.1 442,720.9 441.9,720.6 C438.3,710.1 434.7,699.5 431,689 C430.5,687.4 431.3,686.3 432.3,686.1 C432.7,686 433.2,686 433.7,686.2 C438.8,687.9 443.9,689.7 449.1,691.5 C454.6,693.4 460,695.3 465.5,697.1 C466.5,697.4 467,698.1 467.1,699.1 L467.1,699.1 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd"/>
        <path d="M433.2,675.4 C437.7,675.5 441.3,677.3 443.9,681 C444.6,682 444.5,683.2 443.5,683.9 C442.6,684.6 441.3,684.4 440.6,683.4 C438.7,680.8 436.1,679.5 432.9,679.6 C427.2,679.8 423.2,685.1 424.5,690.7 C425,692.8 426.1,694.5 427.8,695.8 C428,696 428.3,696.1 428.5,696.3 C429.2,697.1 429.3,698.2 428.6,699 C427.9,699.8 426.8,700 425.9,699.3 C424.1,698.1 422.6,696.5 421.7,694.5 C419.6,690 419.8,685.6 422.4,681.5 C424.5,678.2 427.7,676.2 431.6,675.7 C432.1,675.5 432.7,675.4 433.2,675.4 L433.2,675.4 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="0;1;0;1;0"/>
        </path>
        <path d="M453.6,683.1 C453.6,684 452.9,684.7 452,684.8 C451.2,684.9 450.6,684.4 450.2,683.4 C450,683 449.8,682.5 449.7,682 C449.4,681.2 449.1,680.4 448.7,679.6 C445.9,674.5 441.5,671.6 435.7,670.7 C430.1,669.9 425.1,671.5 420.9,675.6 C418,678.4 416.3,681.8 415.8,685.7 C415.7,686.6 415.7,687.4 415.7,688.2 C415.7,694.1 418.3,699 423.4,702.7 C424.7,703.7 426.4,704.5 428.4,705 C428.9,705.2 429.2,705.4 429.6,705.7 C429.8,706 430,706.3 430,706.6 C430,706.9 429.9,707.2 429.7,707.5 C429.4,708.1 428.6,708.5 427.9,708.3 C427.5,708.2 427.1,708 426.8,707.9 C425.8,707.6 424.7,707.2 423.8,706.7 C417.8,703.5 414.1,698.6 412.8,692.2 C412.6,691.4 412.5,690.3 412.4,689.6 C412.4,689.4 412.4,689.1 412.3,689 C412.4,683.1 414.1,678.5 417.5,674.5 C420.8,670.7 424.9,668.4 429.9,667.5 C433.9,666.8 437.8,667.3 441.7,668.9 C446.9,671.1 450.6,674.9 452.9,680.3 C453.2,681 453.4,681.7 453.6,682.4 C453.6,682.7 453.6,682.9 453.6,683.1 L453.6,683.1 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="1;0;1;0;1"/>
        </path>
      </g>
      <g className={"Tips"}
         onClick={onClick2}
         style={{transform: "translate(0, -100px)"}}
         filter={"url(#star04Shadow)"}
      >
        <path d="M435.3,1196.3 C434.4,1196.3 433.7,1195.8 433.3,1194.8 C432.2,1192.2 431.2,1189.6 430.1,1187 C429.3,1185.1 428.6,1183.2 427.8,1181.3 C427.7,1181 427.5,1180.8 427.2,1180.7 C422.7,1178.9 418.2,1177.1 413.7,1175.2 C413.2,1175 412.7,1174.7 412.4,1174.2 C411.9,1173.2 412.2,1172 413.1,1171.4 C413.3,1171.3 413.5,1171.2 413.8,1171.1 C424.3,1167.5 434.9,1163.9 445.4,1160.2 C447,1159.7 448.1,1160.5 448.3,1161.5 C448.4,1161.9 448.4,1162.4 448.2,1162.9 C446.5,1168 444.7,1173.1 442.9,1178.3 C441,1183.8 439.1,1189.2 437.3,1194.7 C437,1195.7 436.3,1196.2 435.3,1196.3 L435.3,1196.3 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd"/>
        <path d="M459,1162.4 C458.9,1166.9 457.1,1170.5 453.4,1173.1 C452.4,1173.8 451.2,1173.7 450.5,1172.7 C449.8,1171.8 450,1170.5 451,1169.8 C453.6,1167.9 454.9,1165.3 454.8,1162.1 C454.6,1156.4 449.3,1152.4 443.7,1153.7 C441.6,1154.2 439.9,1155.3 438.6,1157 C438.4,1157.2 438.3,1157.5 438.1,1157.7 C437.3,1158.4 436.2,1158.5 435.4,1157.8 C434.6,1157.1 434.4,1156 435.1,1155.1 C436.3,1153.3 437.9,1151.8 439.9,1150.9 C444.4,1148.8 448.8,1149 452.9,1151.6 C456.2,1153.7 458.2,1156.9 458.7,1160.8 C458.9,1161.3 459,1161.9 459,1162.4 L459,1162.4 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="0;1;0;1;0"/>
        </path>
        <path d="M451.3,1182.8 C450.4,1182.8 449.7,1182.1 449.6,1181.2 C449.5,1180.4 450,1179.8 451,1179.4 C451.4,1179.2 451.9,1179 452.4,1178.9 C453.2,1178.6 454,1178.3 454.8,1177.9 C459.9,1175.1 462.8,1170.7 463.7,1164.9 C464.5,1159.3 462.9,1154.3 458.8,1150.1 C456,1147.2 452.6,1145.5 448.7,1145 C447.8,1144.9 447,1144.9 446.2,1144.9 C440.3,1144.9 435.4,1147.5 431.7,1152.6 C430.7,1153.9 429.9,1155.6 429.4,1157.6 C429.2,1158.1 429,1158.4 428.7,1158.8 C428.4,1159 428.1,1159.2 427.8,1159.2 C427.5,1159.2 427.2,1159.1 426.9,1158.9 C426.3,1158.6 425.9,1157.8 426.1,1157.1 C426.2,1156.7 426.4,1156.3 426.5,1156 C426.8,1155 427.2,1153.9 427.7,1153 C430.9,1147 435.8,1143.3 442.2,1142 C443,1141.8 444.1,1141.7 444.8,1141.6 C445,1141.6 445.3,1141.6 445.4,1141.5 C451.3,1141.6 455.9,1143.3 459.9,1146.7 C463.7,1150 466,1154.1 466.9,1159.1 C467.6,1163.1 467.1,1167 465.5,1170.9 C463.3,1176.1 459.5,1179.8 454.1,1182.1 C453.4,1182.4 452.7,1182.6 452,1182.8 C451.7,1182.8 451.5,1182.8 451.3,1182.8 L451.3,1182.8 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="1;0;1;0;1"/>
        </path>
      </g>
    </g>
  </svg>
}


