import React from "react";

const cx = 130 + 295 - 10
const cy = 323 + 295
let xStr1 = ""
let yStr1 = ""
for (let i = 10; i <= 90; i += 1) {
  xStr1 += `${cx + Math.cos(i / 180 * Math.PI) * 295.0};`
  yStr1 += `${cy + Math.sin(i / 180 * Math.PI) * 295.0};`
}

let xStr2 = ""
let yStr2 = ""
for (let i = 200; i <= 270; i += 1) {
  xStr2 += `${cx + Math.cos(i / 180 * Math.PI) * 295.0};`
  yStr2 += `${cy + Math.sin(i / 180 * Math.PI) * 295.0};`
}

export default ({className, style, onClick1, onClick2}) => {
  return <svg
    className={className}
    style={style}
    viewBox="-172 351 815 1500"
    version="1.1"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter id="star03Shadow" x="0" y="0" width="200%" height="200%">
        <feOffset result="offOut" in="SourceGraphic" dx="5" dy="5" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
      <radialGradient cx="50.3023935%" cy="49.624681%" fx="50.3023935%" fy="49.624681%" r="50.0459494%"
                      id="star03RadialGradient-1">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3662547%" cy="49.5604883%" fx="50.3662547%" fy="49.5604883%" r="50.0374684%"
                      id="star03RadialGradient-2">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
    </defs>
    <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
       transform="translate(-172.000000, 351.000000)">
      <rect x="0" y="0" width="815" height="1500"/>
      <circle
        onClick={onClick1}
        fill="url(#star03RadialGradient-1)" cx="415" cy="323" r="39.5">
        <animate attributeName="r" begin="0s" dur="7s" repeatCount="indefinite" values="10;80;20;80.5;20;80;40;20;10"/>
        <animate attributeName="cx" begin="0s" dur="3s" values={xStr2}/>
        <animate attributeName="cy" begin="0s" dur="3s" values={yStr2}/>
      </circle>
      <circle
        onClick={onClick2}
        fill="url(#star03RadialGradient-2)" cx="415" cy="913" r="39.5">
        <animate attributeName="r" begin="0s" dur="10s" repeatCount="indefinite" values="10;80;20;80.5;20;80;40;20;10"/>
        <animate attributeName="cx" begin="0s" dur="3s" values={xStr1}/>
        <animate attributeName="cy" begin="0s" dur="3s" values={yStr1}/>
      </circle>
      <g
        className={"Tips"}
        onClick={onClick2}
        filter={"url(#star03Shadow)"}
      >
        <path d="M423.8,1022.4 C423.2,1023 422.3,1023.2 421.3,1022.8 C418.7,1021.7 416.1,1020.6 413.5,1019.5 C411.6,1018.7 409.8,1017.9 407.8,1017.1 C407.5,1017 407.2,1017 407,1017.1 C402.5,1019 398.1,1020.9 393.6,1022.8 C393.1,1023 392.5,1023.2 392,1023 C390.9,1022.6 390.3,1021.6 390.5,1020.5 C390.6,1020.3 390.6,1020.1 390.8,1019.8 C395.7,1009.8 400.6,999.8 405.4,989.7 C406.2,988.2 407.5,988 408.4,988.6 C408.8,988.8 409.1,989.2 409.3,989.7 C411.7,994.5 414,999.4 416.4,1004.3 C418.9,1009.5 421.4,1014.7 424,1019.9 C424.6,1020.8 424.4,1021.6 423.8,1022.4 L423.8,1022.4 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd"/>
        <path d="M416.6,981.7 C419.7,985 421,988.8 420.2,993.2 C420,994.4 419.1,995.2 417.9,995 C416.8,994.9 416,993.8 416.2,992.6 C416.7,989.4 415.8,986.7 413.4,984.5 C409.2,980.6 402.7,981.5 399.6,986.4 C398.5,988.2 398,990.2 398.3,992.3 C398.3,992.6 398.4,992.9 398.4,993.1 C398.3,994.2 397.6,995 396.6,995.1 C395.5,995.2 394.6,994.5 394.5,993.4 C394.1,991.3 394.1,989.1 394.9,987 C396.6,982.3 399.8,979.4 404.6,978.3 C408.4,977.5 412.1,978.3 415.2,980.7 C415.7,981 416.2,981.3 416.6,981.7 L416.6,981.7 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="0;1;0;1;0"/>
        </path>
        <path d="M425.5,1001.6 C424.8,1002.3 423.9,1002.3 423.2,1001.6 C422.6,1001.1 422.5,1000.3 422.9,999.3 C423.1,998.8 423.3,998.4 423.5,998 C423.8,997.2 424.2,996.4 424.5,995.6 C426.1,990 425.1,984.8 421.6,980.1 C418.2,975.5 413.5,973.2 407.7,973.1 C403.7,973 400.1,974.2 397,976.6 C396.3,977.1 395.7,977.7 395.1,978.3 C390.9,982.5 389.3,987.8 390.3,993.9 C390.6,995.5 391.2,997.2 392.2,999.1 C392.4,999.6 392.5,999.9 392.5,1000.4 C392.5,1000.8 392.3,1001.1 392.1,1001.3 C391.9,1001.5 391.6,1001.6 391.3,1001.7 C390.6,1001.9 389.8,1001.7 389.5,1001 C389.3,1000.6 389.1,1000.3 389,999.9 C388.5,998.9 388,997.9 387.7,996.9 C385.7,990.4 386.6,984.4 390.1,978.8 C390.5,978.1 391.2,977.2 391.7,976.6 C391.8,976.4 392,976.2 392.1,976.1 C396.3,972 400.8,969.9 406,969.6 C411,969.3 415.5,970.5 419.7,973.4 C423,975.7 425.5,978.9 427.1,982.7 C429.2,988 429.2,993.2 427,998.7 C426.7,999.4 426.4,1000 426,1000.6 C425.8,1001.2 425.7,1001.4 425.5,1001.6 L425.5,1001.6 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="1;0;1;0;1"/>
        </path>
      </g>
      <g
        className={"Tips"}
        onClick={onClick1}
        filter={"url(#star03Shadow)"}
      >
        <path d="M391,234.3 C391.6,233.7 392.5,233.5 393.5,233.9 C396.1,235 398.7,236.1 401.3,237.2 C403.2,238 405,238.8 407,239.6 C407.3,239.7 407.6,239.7 407.8,239.6 C412.3,237.7 416.7,235.8 421.2,233.9 C421.7,233.7 422.3,233.5 422.8,233.7 C423.9,234.1 424.5,235.1 424.3,236.2 C424.2,236.4 424.2,236.6 424,236.9 C419.1,246.9 414.2,256.9 409.4,267 C408.6,268.5 407.3,268.7 406.4,268.1 C406,267.9 405.7,267.5 405.5,267 C403.1,262.2 400.8,257.3 398.4,252.4 C395.9,247.2 393.4,242 390.8,236.8 C390.2,235.9 390.3,235.1 391,234.3 L391,234.3 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd"/>
        <path d="M398.2,275 C395.1,271.7 393.8,267.9 394.6,263.5 C394.8,262.3 395.7,261.5 396.9,261.7 C398,261.8 398.8,262.9 398.6,264.1 C398.1,267.3 399,270 401.4,272.2 C405.6,276.1 412.1,275.2 415.2,270.3 C416.3,268.5 416.8,266.5 416.5,264.4 C416.5,264.1 416.4,263.8 416.4,263.6 C416.5,262.5 417.2,261.7 418.2,261.6 C419.3,261.5 420.2,262.2 420.3,263.3 C420.7,265.4 420.7,267.6 419.9,269.7 C418.2,274.4 415,277.3 410.2,278.4 C406.4,279.2 402.7,278.4 399.6,276 C399,275.7 398.5,275.4 398.2,275 L398.2,275 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="0;1;0;1;0"/>
        </path>
        <path d="M389.2,255.2 C389.9,254.5 390.8,254.5 391.5,255.2 C392.1,255.7 392.2,256.5 391.8,257.5 C391.6,258 391.4,258.4 391.2,258.8 C390.9,259.6 390.5,260.4 390.2,261.2 C388.6,266.8 389.6,272 393.1,276.7 C396.5,281.3 401.2,283.6 407,283.7 C411,283.8 414.6,282.6 417.7,280.2 C418.4,279.7 419,279.1 419.6,278.5 C423.8,274.3 425.4,269 424.4,262.9 C424.1,261.3 423.5,259.6 422.5,257.7 C422.3,257.2 422.2,256.9 422.2,256.4 C422.2,256 422.4,255.7 422.6,255.5 C422.8,255.3 423.1,255.2 423.4,255.1 C424.1,254.9 424.9,255.1 425.2,255.8 C425.4,256.2 425.6,256.5 425.7,256.9 C426.2,257.9 426.7,258.9 427,259.9 C429,266.4 428.1,272.4 424.6,278 C424.2,278.7 423.5,279.6 423,280.2 C422.9,280.4 422.7,280.6 422.6,280.7 C418.4,284.8 413.9,286.9 408.7,287.2 C403.7,287.5 399.2,286.3 395,283.4 C391.7,281.1 389.2,277.9 387.6,274.1 C385.5,268.8 385.5,263.6 387.7,258.1 C388,257.4 388.3,256.8 388.7,256.2 C388.9,255.5 389.1,255.3 389.2,255.2 L389.2,255.2 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="1;0;1;0;1"/>
        </path>
      </g>
    </g>
  </svg>
}

