import React, {useEffect, useRef, useState} from 'react';
import "./Scenes.css"
import Star01 from "./Star01";
import Star02 from "./Star02";
import Star03 from "./Star03";
import Star04 from "./Star04";
import Modal from "antd/lib/modal"
import "antd/lib/modal/style/index.less"
import TextS101 from "./TextS101"
import TextS102 from "./TextS102"
import TextS201 from "./TextS201"
import TextS202 from "./TextS202"
import TextS301 from "./TextS301"
import TextS302 from "./TextS302"
import TextS401 from "./TextS401"
import TextS402 from "./TextS402"

import Swiper from 'react-id-swiper';
import 'swiper/swiper.less';

let showArrowTimer
let scrollingTimer
let swiperStopTimer
let SceneText = null

function Scenes({style, width, height}) {
  const [showNext, setShowNext] = useState(false)
  const [showModal, setShowModal] = useState(null)
  const [showTextModal, setShowTextModal] = useState(null)
  const [selectedText, setSelectedText] = useState(null)
  const scenesContainer = useRef()
  const [loads, setLoads] = useState({})
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0)
  const [showArrowIndex, setShowArrowIndex] = useState(null)
  const slideChange = (swiper) => {
    if (swiper !== null) {
      if (swiper.activeIndex % 5 === 3) {
        if (swiper.swipeDirection === "next" || !swiper.swipeDirection) {
          clearTimeout(swiperStopTimer)
          setTimeout(() => {
            swiper.slideNext(5000)
          }, 500)
        } else {
          clearTimeout(swiperStopTimer)
          setTimeout(() => {
            swiper.slidePrev(5000)
          }, 500)
        }
      }
      setShowNext(false)
      setActiveIndex(swiper.activeIndex % 5)
      clearTimeout(showArrowTimer)
      showArrowTimer = setTimeout(() => {
        setShowArrowIndex(swiper.activeIndex % 5)
      }, 2300)
    }
  }

  const params = {
    direction: "vertical",
    centeredSlides: true,
    slidesPerView: 'auto',
    speed: 2500,
    preloadImages: true,
    // lazy: {
    //   loadPrevNext: true,
    // },
    on: {
      slideChange,
    }
  }

  useEffect(() => {
    showArrowTimer = setTimeout(() => {
      setShowArrowIndex(activeIndex)
    }, 1000)
    return () => {
      clearTimeout(showArrowTimer)
      clearTimeout(scrollingTimer)
    }
  }, [])

  switch (selectedText) {
    case "texts101":
      SceneText = <TextS101/>
      break
    case "texts102":
      SceneText = <TextS102/>
      break
    case "texts201":
      SceneText = <TextS201/>
      break
    case "texts202":
      SceneText = <TextS202/>
      break
    case "texts301":
      SceneText = <TextS301/>
      break
    case "texts302":
      SceneText = <TextS302/>
      break
    case "texts401":
      SceneText = <TextS401/>
      break
    case "texts402":
      SceneText = <TextS402/>
      break
    default:
  }
  const textsIn = {
    "texts101": "animate__fadeInDown  animate__animated",
    "texts102": "animate__fadeInUp  animate__animated",
    "texts201": "animate__fadeInDown animate__animated",
    "texts202": "animate__fadeInUp animate__animated",
    "texts301": "animate__fadeInDown animate__animated",
    "texts302": "animate__fadeInUp animate__animated",
    "texts401": "animate__fadeInDown animate__animated",
    "texts402": "animate__fadeInUp animate__animated",
  }
  const textsOut = {
    "texts101": "animate__fadeOutUp animate__animated",
    "texts102": "animate__fadeOutDown animate__animated",
    "texts201": "animate__fadeOutUp animate__animated",
    "texts202": "animate__fadeOutDown animate__animated",
    "texts301": "animate__fadeOutUp animate__animated",
    "texts302": "animate__fadeOutDown animate__animated",
    "texts401": "animate__fadeOutUp animate__animated",
    "texts402": "animate__fadeOutDown animate__animated",
  }
  return (<div
    className={"Scenes"}
    style={style}
    ref={scenesContainer}
  >
    <div className={"SceneWrapper"}>
      <Swiper  {...params} ref={swiperRef}>
        <div className={"Scene"}
             style={{height: (width + 64) / 814.0 * 1500}}>
          <div
            className={"Scene-BG"}
            style={{
              backgroundImage: `url(/bj01-sk.jpg)`
            }}
          />
          <img
            alt={"SCENE01"}
            className={"animate__fadeIn animate__slower animate__animated"}
            src={"/scene01.png"}
            onLoad={() => {
              setLoads({...loads, "sc01": true})
            }}/>
          {(loads["sc01"] && activeIndex === 0) &&
          <Star01
            className={`
            animate__fadeIn animate__faster animate__animated
            SceneStar 
            ${(loads["sc01"] && activeIndex === 0) ? "SceneStarShow" : ""} 
            ${(loads["sc01"] && activeIndex === 0 && showArrowIndex === activeIndex) ? "SceneArrowShow" : ""}`}
            style={{
              height: (width + 64) / 814.0 * 1500,
            }}
            onClick1={() => {
              setShowTextModal(true)
              setSelectedText("texts101")
              setShowNext(false)
            }}
            onClick2={() => {
              setShowTextModal(true)
              setSelectedText("texts102")
              setShowNext(false)
            }}
          />
          }
        </div>
        <div className={"Scene"}
             style={{height: (width + 64) / 814.0 * 1500}}
        >
          <div
            className={"Scene-BG"}
            style={{
              backgroundImage: `url(/bj02-sk.jpg)`
            }}
          />
          <img
            alt={"SCENE02"}
            src={"/scene02.png"} onLoad={() => {
            setLoads({...loads, "sc02": true})
          }}/>
          {(loads["sc02"] && activeIndex === 1) &&
          <Star02
            className={`
            animate__fadeIn animate__faster animate__animated
            SceneStar 
            ${(loads["sc02"] && activeIndex === 1) ? "SceneStarShow" : ""} 
            ${(loads["sc02"] && activeIndex === 1 && showArrowIndex === activeIndex) ? "SceneArrowShow" : ""}`}
            style={{
              height: (width + 64) / 814.0 * 1500,
            }}
            onClick1={() => {
              setShowTextModal(true)
              setSelectedText("texts201")
              setShowNext(false)
            }}
            onClick2={() => {
              setShowTextModal(true)
              setSelectedText("texts202")
              setShowNext(false)
            }}
          />
          }
        </div>
        <div className={"Scene"}
             style={{height: (width + 64) / 814.0 * 1500}}>
          <div
            className={"Scene-BG"}
            style={{
              backgroundImage: `url(/bj03-sk.jpg)`
            }}
          />
          <img
            alt={"SCENE03"}
            src={"/scene03.png"} onLoad={() => {
            setLoads({...loads, "sc03": true})
          }}/>
          {(loads["sc03"] && activeIndex === 2) &&
          <Star03
            className={`
            animate__fadeIn animate__faster animate__animated
            SceneStar 
            ${(loads["sc03"] && activeIndex === 2) ? "SceneStarShow" : ""} 
            ${(loads["sc03"] && activeIndex === 2 && showArrowIndex === activeIndex) ? "SceneArrowShow" : ""}`}
            style={{
              height: (width + 64) / 814.0 * 1500,
            }}
            onClick1={() => {
              setShowTextModal(true)
              setSelectedText("texts301")
              setShowNext(false)
            }}
            onClick2={() => {
              setShowTextModal(true)
              setSelectedText("texts302")
              setShowNext(false)
            }}
          />
          }
        </div>
        <div className={"Scene"}
             style={{height: (width + 64) / 814.0 * 769}}>
          <div
            className={"Scene-BG"}
            style={{
              backgroundImage: `url(/bj3-4-sk.jpg)`
            }}
          />
        </div>
        <div className={"Scene"}
             style={{height: (width + 64) / 814.0 * 1500}}>
          <div
            className={"Scene-BG"}
            style={{
              backgroundImage: `url(/bj04-sk.jpg)`
            }}
          />
          <img
            alt={"SCENE04"}
            src={"/scene04.png"} onLoad={() => {
            setLoads({...loads, "sc04": true})
          }}/>
          {(loads["sc04"] && activeIndex === 4) &&
          <Star04
            className={`
            animate__fadeIn animate__slower animate__animated
            SceneStar 
            ${(loads["sc04"] && activeIndex === 4) ? "SceneStarShow" : ""} 
            ${(loads["sc04"] && activeIndex === 4 && showArrowIndex === activeIndex) ? "SceneArrowShow" : ""}`}
            style={{
              height: (width + 64) / 814.0 * 1500,
            }}
            onClick1={() => {
              setShowTextModal(true)
              setSelectedText("texts401")
              setShowNext(false)
            }}
            onClick2={() => {
              setShowTextModal(true)
              setSelectedText("texts402")
              setShowNext(false)
            }}
          />
          }
        </div>
      </Swiper>
    </div>
    <Modal
      visible={!!showModal || !!showTextModal}
      className={"ShareModal"}
      closable={false}
      footer={null}
      onCancel={() => {
        if (!!showModal) {
          setShowModal(null)
          setShowNext(true)
        } else if (!!showTextModal) {
          setShowTextModal(null)
        }
      }}
    >
      {!!selectedText && <div className={`
        TextContainer TextContainer-${selectedText}
      `} onClick={() => {
        setShowTextModal(null)
        setShowNext(true)
      }}>
        <div className={`TextWrapper ${showTextModal ? textsIn[selectedText] : textsOut[selectedText]}`}
             onClick={(e) => {
               e.preventDefault()
               e.stopPropagation()
               switch (selectedText) {
                 case "texts101":
                   setShowTextModal(null)
                   setShowModal("/card_s1_01.jpg")
                   setShowNext(false)
                   break
                 case "texts102":
                   setShowTextModal(null)
                   setShowModal("/card_s1_02.jpg")
                   setShowNext(false)
                   break
                 case "texts201":
                   setShowTextModal(null)
                   setShowModal("/card_s2_01.jpg")
                   setShowNext(false)
                   break
                 case "texts202":
                   setShowTextModal(null)
                   setShowModal("/card_s2_02.jpg")
                   setShowNext(false)
                   break
                 case "texts301":
                   setShowTextModal(null)
                   setShowModal("/card_s3_01.jpg")
                   setShowNext(false)
                   break
                 case "texts302":
                   setShowTextModal(null)
                   setShowModal("/card_s3_02.jpg")
                   setShowNext(false)
                   break
                 case "texts401":
                   setShowTextModal(null)
                   setShowModal("/card_s4_01.jpg")
                   setShowNext(false)
                   break
                 case "texts402":
                   setShowTextModal(null)
                   setShowModal("/card_s4_02.jpg")
                   setShowNext(false)
                   break
                 default:
                   setShowTextModal(null)
                   setShowModal(null)
                   setShowNext(false)
               }
             }}
        >
          {SceneText}
        </div>
      </div>
      }
      {!!showModal &&
      <div className={"ShareImage animate__fadeIn animate__animated"}
           style={{width: width, height: height - 99}}
      >
        {showModal &&
        <img
          alt={"SHARE1"}
          src={showModal.replace(".jpg", "_fx.jpg")}/>
        }
        {showModal &&
        <img src={showModal}
             alt={"SHARE2"}
        />
        }
      </div>
      }
      {!!showModal &&
      <div className={"ShareText animate__fadeInUp animate__animated"}>
        <div>
          长按保存态度卡片，分享旅行箴言，
        </div>
        <div>
          与海蓝之谜一同探索治愈之旅。
        </div>
        <div className={"ShareClose"}
             onClick={() => {
               setShowModal(null)
               setShowNext(true)
             }}
        >

          <svg width="18px" height="18px" viewBox="-1 -1 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path d="M0.53,0.53 L33.29,33.29" stroke="#FFFFFF" strokeWidth="1.5"/>
              <path d="M0.53,33.29 L33.29,0.53" stroke="#FFFFFF" strokeWidth="1.5"/>
            </g>
          </svg>
        </div>
      </div>
      }
    </Modal>
    <div className={`Next ${(showNext && activeIndex !== 4) ? "" : "NextHidden"}`}
         onClick={() => {
           if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
             swiperRef.current.swiper.slideNext(2500)
           }
         }}
    >
      <svg width="25px" height="13px" viewBox="0 0 49 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.69,22.94 L1.44,3.56 C0.85,2.97 0.85,2.02 1.44,1.44 C2.03,0.86 2.98,0.85 3.56,1.44 L22.81,20.82 C23.74,21.75 25.24,21.75 26.17,20.82 L45.87,1.44 C46.46,0.85 47.41,0.85 47.99,1.44 C48.57,2.03 48.58,2.98 47.99,3.56 L28.29,22.94 C26.2,25.04 22.79,25.04 20.69,22.94 L20.69,22.94 Z"
          stroke="none" fill="#FFFFFF" fillRule="evenodd"/>
      </svg>
    </div>
  </div>)
}

export default Scenes

