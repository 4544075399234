import React from "react"

export default ({className, style, onClick1, onClick2}) => {
  return <svg
    className={className}
    style={style}
    viewBox="0 0 814 1500"
    version="1.1"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter id="star03Shadow" x="0" y="0" width="200%" height="200%">
        <feOffset result="offOut" in="SourceGraphic" dx="5" dy="5" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
      <radialGradient cx="50.3079247%" cy="49.566208%" fx="50.3079247%" fy="49.566208%" r="50.0495084%"
                      id="star01RadialGradient-1">
        <stop stopColor="#ffffff" offset="3.48%"/>
        <stop stopColor="#ffffff" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#ffffff" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3142098%" cy="49.5574724%" fx="50.3142098%" fy="49.5574724%" r="50.0331812%"
                      id="star01RadialGradient-2">
        <stop stopColor="#ffffff" offset="3.48%"/>
        <stop stopColor="#ffffff" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#ffffff" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3109492%" cy="49.5619521%" fx="50.3109492%" fy="49.5619521%" r="50.0533708%"
                      id="star01RadialGradient-3">
        <stop stopColor="#ffffff" offset="3.48%"/>
        <stop stopColor="#ffffff" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#ffffff" stopOpacity="0" offset="90%"/>
      </radialGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="rectangle-path" x="0" y="0" width="815" height="1500"/>
      <circle
        onClick={onClick1}
        fill="url(#star01RadialGradient-1)" cx="458.32" cy="620.33" r={`28.48`}>
        <animate attributeName="cx" begin="0s" dur="90s" repeatCount="indefinite"
                 values="307;458;458;458;458;458;458;458;458;458;307;"/>
        <animate attributeName="cy" begin="0s" dur="90s" repeatCount="indefinite"
                 values="541;620;620;620;620;620;620;620;620;620;541;"/>
        <animate attributeName="r" begin="0s" dur="10s" repeatCount="indefinite" values="10;80;40;80;10;"/>
      </circle>
      <circle
        onClick={onClick2}
        fill="url(#star01RadialGradient-2)" cx="252.65" cy="829.42" r={`28.48`}>
        <animate attributeName="r" begin="0s" dur="10s" repeatCount="indefinite" values="28.48;80;28.48;80;40;28.48"/>
        <animate attributeName="cx" begin="0s" dur="90s" repeatCount="indefinite"
                 values="250;300;400;400;400;400;400;400;400;400;400;400;300;250"/>
        <animate attributeName="cy" begin="0s" dur="90s" repeatCount="indefinite"
                 values="825;829;832;832;832;832;832;832;832;832;832;832;829;825"/>
      </circle>
      <circle
        onClick={onClick1}
        fill="url(#star01RadialGradient-3)" cx="112.68" cy="411.68" r={`20.48`}>
        <animate attributeName="r" begin="0s" dur="10s" repeatCount="indefinite" values="10;30;40;30;10;"/>
      </circle>
      <g className={"Tips"}
         onClick={onClick1}
         filter={"url(#star03Shadow)"}
      >
        <path d="M553.4,554.8 C554.3,554.8 555,555.3 555.4,556.3 C556.5,558.9 557.5,561.5 558.6,564.1 C559.4,566 560.1,567.9 560.9,569.8 C561,570.1 561.2,570.3 561.5,570.4 C566,572.2 570.5,574 575,575.9 C575.5,576.1 576,576.4 576.3,576.9 C576.8,577.9 576.5,579.1 575.6,579.7 C575.4,579.8 575.2,579.9 574.9,580 C564.4,583.6 553.8,587.2 543.3,590.9 C541.7,591.4 540.6,590.6 540.4,589.6 C540.3,589.2 540.3,588.7 540.5,588.2 C542.2,583.1 544,578 545.8,572.8 C547.7,567.3 549.6,561.9 551.4,556.4 C551.7,555.4 552.4,554.9 553.4,554.8 L553.4,554.8 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd"/>
        <path d="M529.7,588.7 C529.8,584.2 531.6,580.6 535.3,578 C536.3,577.3 537.5,577.4 538.2,578.4 C538.9,579.3 538.7,580.6 537.7,581.3 C535.1,583.2 533.8,585.8 533.9,589 C534.1,594.7 539.4,598.7 545,597.4 C547.1,596.9 548.8,595.8 550.1,594.1 C550.3,593.9 550.4,593.6 550.6,593.4 C551.4,592.7 552.5,592.6 553.3,593.3 C554.1,594 554.3,595.1 553.6,596 C552.4,597.8 550.8,599.3 548.8,600.2 C544.3,602.3 539.9,602.1 535.8,599.5 C532.5,597.4 530.5,594.2 530,590.3 C529.8,589.8 529.7,589.2 529.7,588.7 L529.7,588.7 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="0;1;0;1;0"/>
        </path>
        <path d="M537.4,568.3 C538.3,568.3 539,569 539.1,569.9 C539.2,570.7 538.7,571.3 537.7,571.7 C537.3,571.9 536.8,572.1 536.3,572.2 C535.5,572.5 534.7,572.8 533.9,573.2 C528.8,576 525.9,580.4 525,586.2 C524.2,591.8 525.8,596.8 529.9,601 C532.7,603.9 536.1,605.6 540,606.1 C540.9,606.2 541.7,606.2 542.5,606.2 C548.4,606.2 553.3,603.6 557,598.5 C558,597.2 558.8,595.5 559.3,593.5 C559.5,593 559.7,592.7 560,592.3 C560.3,592.1 560.6,591.9 560.9,591.9 C561.2,591.9 561.5,592 561.8,592.2 C562.4,592.5 562.8,593.3 562.6,594 C562.5,594.4 562.3,594.8 562.2,595.1 C561.9,596.1 561.5,597.2 561,598.1 C557.8,604.1 552.9,607.8 546.5,609.1 C545.7,609.3 544.6,609.4 543.9,609.5 C543.7,609.5 543.4,609.5 543.3,609.6 C537.4,609.5 532.8,607.8 528.8,604.4 C525,601.1 522.7,597 521.8,592 C521.1,588 521.6,584.1 523.2,580.2 C525.4,575 529.2,571.3 534.6,569 C535.3,568.7 536,568.5 536.7,568.3 C537,568.3 537.2,568.3 537.4,568.3 L537.4,568.3 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="1;0;1;0;1"/>
        </path>
      </g>
      <g className={"Tips"}
         onClick={onClick2}
         filter={"url(#star03Shadow)"}
      >
        <path d="M189,889.3 C188.1,889.3 187.4,888.8 187,887.8 C185.9,885.2 184.9,882.6 183.8,880 C183,878.1 182.3,876.2 181.5,874.3 C181.4,874 181.2,873.8 180.9,873.7 C176.4,871.9 171.9,870.1 167.4,868.2 C166.9,868 166.4,867.7 166.1,867.2 C165.6,866.2 165.9,865 166.8,864.4 C167,864.3 167.2,864.2 167.5,864.1 C178,860.5 188.6,856.9 199.1,853.2 C200.7,852.7 201.8,853.5 202,854.5 C202.1,854.9 202.1,855.4 201.9,855.9 C200.2,861 198.4,866.1 196.6,871.3 C194.7,876.8 192.8,882.2 191,887.7 C190.7,888.7 190,889.2 189,889.3 L189,889.3 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd"/>
        <path d="M212.7,855.4 C212.6,859.9 210.8,863.5 207.1,866.1 C206.1,866.8 204.9,866.7 204.2,865.7 C203.5,864.8 203.7,863.5 204.7,862.8 C207.3,860.9 208.6,858.3 208.5,855.1 C208.3,849.4 203,845.4 197.4,846.7 C195.3,847.2 193.6,848.3 192.3,850 C192.1,850.2 192,850.5 191.8,850.7 C191,851.4 189.9,851.5 189.1,850.8 C188.3,850.1 188.1,849 188.8,848.1 C190,846.3 191.6,844.8 193.6,843.9 C198.1,841.8 202.5,842 206.6,844.6 C209.9,846.7 211.9,849.9 212.4,853.8 C212.6,854.3 212.7,854.9 212.7,855.4 L212.7,855.4 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="0;1;0;1;0"/>
        </path>
        <path d="M205,875.8 C204.1,875.8 203.4,875.1 203.3,874.2 C203.2,873.4 203.7,872.8 204.7,872.4 C205.1,872.2 205.6,872 206.1,871.9 C206.9,871.6 207.7,871.3 208.5,870.9 C213.6,868.1 216.5,863.7 217.4,857.9 C218.2,852.3 216.6,847.3 212.5,843.1 C209.7,840.2 206.3,838.5 202.4,838 C201.5,837.9 200.7,837.9 199.9,837.9 C194,837.9 189.1,840.5 185.4,845.6 C184.4,846.9 183.6,848.6 183.1,850.6 C182.9,851.1 182.7,851.4 182.4,851.8 C182.1,852 181.8,852.2 181.5,852.2 C181.2,852.2 180.9,852.1 180.6,851.9 C180,851.6 179.6,850.8 179.8,850.1 C179.9,849.7 180.1,849.3 180.2,849 C180.5,848 180.9,846.9 181.4,846 C184.6,840 189.5,836.3 195.9,835 C196.7,834.8 197.8,834.7 198.5,834.6 C198.7,834.6 199,834.6 199.1,834.5 C205,834.6 209.6,836.3 213.6,839.7 C217.4,843 219.7,847.1 220.6,852.1 C221.3,856.1 220.8,860 219.2,863.9 C217,869.1 213.2,872.8 207.8,875.1 C207.1,875.4 206.4,875.6 205.7,875.8 C205.4,875.8 205.2,875.8 205,875.8 L205,875.8 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="1;0;1;0;1"/>
        </path>
      </g>
    </g>
  </svg>
}


