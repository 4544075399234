import React from "react";

export default ({className, style}) => <svg
  viewBox="102 195 301 61"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  className={className}
  style={style}
>
  <g stroke="none" strokeWidth="1" fill="url(#lamerLogoGradient)" fillRule="evenodd"
     transform="translate(102.000000, 195.000000)">
    <path
      d="M299.43,57.95 C295.93,57.95 292.96,57.34 289.21,50.15 C285.79,43.58 284.78,41.68 282.66,37.47 C280.45,33.1 276.56,31.34 273.12,30.61 C281.61,30.41 287.79,25.52 287.79,16.7 C287.79,8.47 281.33,2.09 272.56,2.09 L241.71,2.09 L241.71,3.52 L248.43,3.52 L248.43,57.96 L241.71,57.96 L241.71,59.39 L264.57,59.39 L264.57,57.96 L257.84,57.96 L257.84,31.46 L262.33,31.46 C268.8,31.46 270.18,34.6 272.05,38.35 C275.12,44.51 276.09,46.45 278.71,51.68 C281.48,57.22 284.29,59.72 291.24,59.72 C295.78,59.72 300.99,59.39 300.99,59.39 L300.99,57.96 L299.43,57.96 L299.43,57.95 Z M267.99,30.01 L257.84,30.01 L257.84,3.51 L267.99,3.51 C275.34,3.51 277.67,9.32 277.67,16.7 C277.67,23.88 275.4,30.01 267.99,30.01 L267.99,30.01 Z"
    />
    <path
      d="M219.5,57.95 L207.22,57.95 L207.22,31.45 L211.63,31.45 C218.29,31.45 223.68,36.85 223.68,43.5 L224.74,43.5 L224.74,17.95 L223.68,17.95 C223.68,24.61 218.28,30 211.63,30 L207.22,30 L207.22,3.5 L220.85,3.5 C227.95,3.5 233.71,9.26 233.71,16.36 L234.84,16.36 L234.84,2.07 L191.08,2.07 L191.08,3.5 L197.8,3.5 L197.8,57.94 L191.08,57.94 L191.08,59.37 L237.14,59.37 L237.14,41.44 L236,41.44 C236,50.57 228.61,57.95 219.5,57.95 L219.5,57.95 Z"
    />
    <polygon
      points="155.27 44.01 140.84 2.08 123.57 2.08 123.57 3.51 130.72 3.51 130.72 57.95 123.57 57.95 123.57 59.38 139.6 59.38 139.6 57.95 132.15 57.95 132.15 5.96 151.16 60.43 170.22 5.39 170.22 57.95 163.48 57.95 163.48 59.38 186.36 59.38 186.36 57.95 179.64 57.95 179.64 3.51 186.36 3.51 186.36 2.08 169.79 2.08"/>
    <path
      d="M28.43,57.95 L16.14,57.95 L16.14,3.51 L22.87,3.51 L22.87,2.08 L0,2.08 L0,3.51 L6.72,3.51 L6.72,57.95 L0,57.95 L0,59.38 L46.06,59.38 L46.06,41.45 L44.93,41.45 C44.93,50.57 37.54,57.95 28.43,57.95 L28.43,57.95 Z"
    />
    <path
      d="M79.27,0.64 L76.65,0.64 L55.04,57.96 L49.36,57.96 L49.36,59.39 L63.63,59.39 L63.63,57.96 L56.63,57.96 L64.38,37.41 L83.19,37.41 L91,57.96 L84.37,57.96 L84.37,59.39 L106.12,59.39 L106.12,57.96 L101.05,57.96 L79.27,0.64 L79.27,0.64 Z M64.92,35.97 L73.75,12.54 L82.65,35.97 L64.92,35.97 L64.92,35.97 Z"
    />
  </g>
  <defs>
    <linearGradient
      gradientUnits={"userSpaceOnUse"}
      gradientTransform={"rotate(15)"}
      id="lamerLogoGradient" x1="0%" y1="0%" x2="100%" y2="100%">

      <stop offset="0%" stopColor="#FDEFDB" stopOpacity="1.0">
        <animate
          attributeName="stop-color"
          // values="#FDEFDB;#FDEFDB;#37DDD2;#2C9361;#05314D;#2C9361;#37DDD2;#FDEFDB;#FDEFDB"
          values="#FDEFDB;#37DDD2;#FDEFDB;#2C9361;#FDEFDB;#05314D;#FDEFDB"
          dur="15s"
          repeatCount="indefinite"
        />
      </stop>

      <stop offset="100%" stopColor="#FDEFDB" stopOpacity="1.0">
        <animate
          attributeName="stop-color"
          // values="#FDEFDB;#FDEFDB;#37DDD2;#2C9361;#05314D;#2C9361;#37DDD2;#FDEFDB;#FDEFDB"
          values="#FDEFDB;#37DDD2;#FDEFDB;#2C9361;#FDEFDB;#05314D;#FDEFDB"
          dur="15s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="offset"
          values="0.95;0.8;0.60;0.40;0.20;0.00;0.20;0.40;0.60;0.80;0.95"
          dur="10s"
          repeatCount="indefinite"
        />
      </stop>
    </linearGradient>
  </defs>
</svg>