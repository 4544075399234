import React from "react";
import "./HomeCover.css"
import LamerLogo from "./LamerLogo";
import HomeText from "./HomeText";

export default ({onClick, onTouchStart, width, height, className}) => {
  return <div className={`HomeCover ${className}`} onTouchStart={onTouchStart} onClick={onClick}>
    <LamerLogo className={"Logo"} style={{
      bottom: width*0.1,
      left: width*0.1,
    }}/>
    <HomeText className={"Texts"} style={{
      width: width*0.8,
      top: width*0.1,
      left: width*0.1,
    }}/>
  </div>
}