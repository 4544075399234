import React, {useEffect, useRef, useState} from 'react';
import {withSize} from 'react-sizeme'
import "./Home.css"
import {Shaders, Node, GLSL} from "gl-react";
import {Surface} from "gl-react-dom"; // for React DOM
import HomeCover from "./HomeCover";
import Scenes from "./Scenes";
import BodyClassName from 'react-body-classname';
import Progress from "./Progress"
import * as Sentry from "@sentry/react";


const shaders = Shaders.create({
  helloBlue: {
    frag: GLSL`
precision highp float;

varying vec2 uv;
uniform float widthRatio;
uniform float backgroundSpeed;
uniform float time;
uniform sampler2D t;

//    Classic Perlin 3D Noise 
//    by Stefan Gustavson
//
vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

float cnoise(vec3 P){
  vec3 Pi0 = floor(P); // Integer part for indexing
  vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
  Pi0 = mod(Pi0, 289.0);
  Pi1 = mod(Pi1, 289.0);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 / 7.0;
  vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 / 7.0;
  vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
  return 2.2 * n_xyz;
}


float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}

float noise(vec3 p){
    vec3 a = floor(p);
    vec3 d = p - a;
    d = d * d * (3.0 - 2.0 * d);

    vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
    vec4 k1 = perm(b.xyxy);
    vec4 k2 = perm(k1.xyxy + b.zzww);

    vec4 c = k2 + a.zzzz;
    vec4 k3 = perm(c);
    vec4 k4 = perm(c + 1.0);

    vec4 o1 = fract(k3 * (1.0 / 41.0));
    vec4 o2 = fract(k4 * (1.0 / 41.0));

    vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
    vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);

    return o4.y * d.y + o4.x * (1.0 - d.y);
}


float fbm(vec3 x) {
    float c = 0.;
    float s = 0.;
    for (float i = 0.5; i <= 1.0; i+= .5) {
        float a = i; //pow(2.0, i);
        float b = 1. / a;
        c += cnoise(x*a)*b;
        s += b;
    }
    return c/s;
}

vec3 hsv2rgb(vec3 c) {
  vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
  vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
  return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

float hue2rgb(float f1, float f2, float hue) {
    if (hue < 0.0)
        hue += 1.0;
    else if (hue > 1.0)
        hue -= 1.0;
    float res;
    if ((6.0 * hue) < 1.0)
        res = f1 + (f2 - f1) * 6.0 * hue;
    else if ((2.0 * hue) < 1.0)
        res = f2;
    else if ((3.0 * hue) < 2.0)
        res = f1 + (f2 - f1) * ((2.0 / 3.0) - hue) * 6.0;
    else
        res = f1;
    return res;
}

vec3 hsl2rgb(vec3 hsl) {
    vec3 rgb;
    
    if (hsl.y == 0.0) {
        rgb = vec3(hsl.z); // Luminance
    } else {
        float f2;
        
        if (hsl.z < 0.5)
            f2 = hsl.z * (1.0 + hsl.y);
        else
            f2 = hsl.z + hsl.y - hsl.y * hsl.z;
            
        float f1 = 2.0 * hsl.z - f2;
        
        rgb.r = hue2rgb(f1, f2, hsl.x + (1.0/3.0));
        rgb.g = hue2rgb(f1, f2, hsl.x);
        rgb.b = hue2rgb(f1, f2, hsl.x - (1.0/3.0));
    }   
    return rgb;
}

vec3 rgb2hsl(vec3 color) {
     vec3 hsl; // init to 0 to avoid warnings ? (and reverse if + remove first part)

     float fmin = min(min(color.r, color.g), color.b); //Min. value of RGB
     float fmax = max(max(color.r, color.g), color.b); //Max. value of RGB
     float delta = fmax - fmin; //Delta RGB value

     hsl.z = (fmax + fmin) / 2.0; // Luminance

     if (delta == 0.0) //This is a gray, no chroma...
     {
         hsl.x = 0.0; // Hue
         hsl.y = 0.0; // Saturation
     } else //Chromatic data...
     {
         if (hsl.z < 0.5)
             hsl.y = delta / (fmax + fmin); // Saturation
         else
             hsl.y = delta / (2.0 - fmax - fmin); // Saturation

         float deltaR = (((fmax - color.r) / 6.0) + (delta / 2.0)) / delta;
         float deltaG = (((fmax - color.g) / 6.0) + (delta / 2.0)) / delta;
         float deltaB = (((fmax - color.b) / 6.0) + (delta / 2.0)) / delta;

         if (color.r == fmax)
             hsl.x = deltaB - deltaG; // Hue
         else if (color.g == fmax)
             hsl.x = (1.0 / 3.0) + deltaR - deltaB; // Hue
         else if (color.b == fmax)
             hsl.x = (2.0 / 3.0) + deltaG - deltaR; // Hue

         if (hsl.x < 0.0)
             hsl.x += 1.0; // Hue
         else if (hsl.x > 1.0)
             hsl.x -= 1.0; // Hue
     }

     return hsl;
 }

void main() {
  vec2 pos = vec2(uv.x/widthRatio, uv.y);
  pos.x += backgroundSpeed*time;
  pos.x = fract(pos.x); 
  // float a = fbm(vec3(uv.x*6.0+time*0.0002, uv.y*10.0, time*0.0005));
  float a = fbm(vec3(uv.x*6.0+time*0.0004, uv.y*10.0, time*0.00075));
  vec3 color = hsv2rgb(vec3(a, 1.0, 1.0));
  vec3 targetColor = rgb2hsl(texture2D(t, pos).xyz);

  float b = smoothstep(0.0, 1.0, a+0.25);
  targetColor.z = mix(
    targetColor.z, 
    max(1.0, targetColor.z+pow(a+0.25, 3.0)), 
    b
  ); 
  gl_FragColor = vec4(hsl2rgb(targetColor), 1.0);
}`
  }
});

let af = null

function Home({size: {width, height}}) {
  const [time, setTime] = useState(0)
  const [loadingImages, setLoadingImages] = useState(true)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [showScenes, setShowScenes] = useState(false)
  const [showCover, setShowCover] = useState(true)
  const [played, setPlayed] = useState(false)
  const [stopped, setStopped] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const audioElement = useRef()
  const playAudio = () => {
    if (!audioElement.current) {
      Sentry.captureMessage("play without audio element");
      return
    }
    const audioPromise = audioElement.current.play()
    if (audioPromise !== undefined) {
      audioPromise.then(_ => { // autoplay started
        setPlayed(true)
      }).catch(err => {
        setPlayed(false)
        Sentry.captureException(err)
        // console.info(err)
      })
    }
  }
  const stopAudio = () => {
    if (!audioElement.current) {
      Sentry.captureMessage("pause without audio element");
      return
    }
    audioElement.current.pause()
    setPlayed(false)
    setStopped(true)
  }
  useEffect(() => {
    const imgs = [
      "/home-bg.jpg",
      // "/music.mp3",
      "/bj01-sk.jpg",
      "/bj02-sk.jpg",
      "/bj03-sk.jpg",
      "/bj3-4-sk.jpg",
      "/bj04-sk.jpg",
      "/scene01.png",
      "/scene02.png",
      "/scene03.png",
      "/scene04.png",
      "/card_s1_01.jpg",
      "/card_s1_02.jpg",
      "/card_s1_01_fx.jpg",
      "/card_s1_02_fx.jpg",
      "/card_s2_01.jpg",
      "/card_s2_02.jpg",
      "/card_s2_01_fx.jpg",
      "/card_s2_02_fx.jpg",
      "/card_s3_01.jpg",
      "/card_s3_02.jpg",
      "/card_s3_01_fx.jpg",
      "/card_s3_02_fx.jpg",
      "/card_s4_01.jpg",
      "/card_s4_02.jpg",
      "/card_s4_01_fx.jpg",
      "/card_s4_02_fx.jpg",
    ]
    const forEachPromise = (items, fn) => {
      return items.reduce(function (promise, item) {
        return promise.then(function () {
          return fn(item);
        });
      }, Promise.resolve());
    }

    let finished = 0.0
    let loadTimer
    const loadImage = (src) => {
      return new Promise(function (resolve, reject) {
        let item;
        if (src.slice(src.length - 3) === "mp3") {
          loadTimer = setTimeout(() => {
            Sentry.captureMessage(`load ${src} timeout in 5000ms`)
            resolve()
          }, 5000)
          item = new Audio(src)
          item.addEventListener('canplaythrough', () => {
            finished += 1
            setLoadingProgress(finished / imgs.length)
            clearTimeout(loadTimer)
            resolve()
          }, false);
          item.src = src;
          item.onerror = (err) => {
            Sentry.captureException(err);
            finished += 1
            setLoadingProgress(finished / imgs.length)
            clearTimeout(loadTimer)
            resolve()
          }
          item.onload = () => {
            finished += 1
            setLoadingProgress(finished / imgs.length)
            clearTimeout(loadTimer)
            resolve()
          }
        } else {
          loadTimer = setTimeout(() => {
            Sentry.captureMessage(`load ${src} timeout in 10000ms`)
            resolve()
          }, 10000)
          item = new Image()
          item.src = src
          item.onload = () => {
            finished += 1
            setLoadingProgress(finished / imgs.length)
            clearTimeout(loadTimer)
            resolve()
          }
          item.onerror = (err) => {
            Sentry.captureException(err);
            finished += 1
            setLoadingProgress(finished / imgs.length)
            clearTimeout(loadTimer)
            resolve()
          }
        }
      })
    }

    forEachPromise(imgs, loadImage).then(() => {
      setLoadingImages(false)
    })

    let timer = setTimeout(() => {
      setLoaded(true)
    }, 1000)
    playAudio()
    let startTime, lastTime;
    let interval = 1000 / 24;
    lastTime = -interval;

    function loop(t) {
      if (!showCover) {
        return
      }
      af = requestAnimationFrame(loop)
      if (!startTime) startTime = t;
      if (t - lastTime > interval) {
        lastTime = t;
        setTime(t - startTime)
      }
    }

    requestAnimationFrame(loop)
    return () => {
      cancelAnimationFrame(af)
      clearTimeout(timer)
      clearTimeout(loadTimer)
    }

  }, [])
  return (<BodyClassName className={`${loaded ? "Loaded" : ""}`}>
      <div className={"Home"}
           onClick={() => {
             if (!played && !stopped) {
               playAudio()
             }
           }}
      >
        {showCover &&
        <Progress
          progress={loadingProgress}
          className={!loadingImages ? "animate__fadeOut animate__faster animate__animated" : ""}
        />
        }
        {(!loadingImages && showCover) && <HomeCover
          className={`${!showScenes ? "animate__fadeIn animate__slower animate__animated" : ""} ${showScenes ? "animate__fadeOut animate__faster animate__animated" : ""}`}
          onClick={()=>{
            if (!loadingImages) {
              cancelAnimationFrame(af)
              setShowScenes(true)
              setTimeout(() => {
                cancelAnimationFrame(af)
                setShowCover(false)
              }, 1000)
            }
          }}
          onTouchStart={() => {
            if (!loadingImages) {
              cancelAnimationFrame(af)
              setShowScenes(true)
              setTimeout(() => {
                cancelAnimationFrame(af)
                setShowCover(false)
              }, 1000)
            }
          }}
          width={width}
          height={height}
        />
        }
        {showCover && <div
          className={`Home-GL ${showScenes ? "animate__fadeOut  animate__slower animate__animated" : ""}`}
        >
          <Surface
            width={width} height={height} pixelRatio={1}>
            <Node
              shader={shaders.helloBlue}
              uniforms={
                {
                  t: "/home-bg.jpg",
                  time: time,
                  widthRatio: 2000.0 / (width * height / 700.0),
                  backgroundSpeed: 0.00001,
                }
              }
            />
          </Surface>
        </div>
        }
        {
          showScenes &&
          <Scenes style={{opacity: showScenes ? "1" : "0"}} width={width} height={height}/>
        }
        <div className={`Music ${played ? "Played" : ""}`}
             onClick={(e) => {
               e.stopPropagation()
               e.preventDefault()
               if (!played) {
                 playAudio()
               } else {
                 stopAudio()
               }
             }}
        >
          <svg className={"PlayButton"} width="56px" height="56px" viewBox="0 0 56 56" version="1.1"
               xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path
                d="M32.66,13.08 L32.66,13.5 L32.66,35.16 C32.66,36.78 32.25,38.25 31.28,39.55 C30.22,40.98 28.81,41.86 27.08,42.21 C25.32,42.56 23.66,42.27 22.13,41.33 C20.88,40.57 19.97,39.51 19.39,38.17 C18.14,35.29 18.98,31.97 21.48,29.97 C23.64,28.24 26.85,28.03 29.21,29.45 C29.59,29.68 29.95,29.94 30.35,30.21 L30.35,29.85 C30.35,23.73 30.35,17.61 30.36,11.48 C30.36,11.2 30.42,10.91 30.5,10.63 C30.6,10.28 31.22,9.95 31.6,10.07 C32.06,10.21 32.53,10.36 32.96,10.58 C35.8,12.04 37.96,14.2 39.35,17.09 C40.4,19.26 40.84,21.56 40.73,23.96 C40.7,24.56 40.17,25.04 39.57,25.03 C38.96,25.02 38.47,24.54 38.43,23.93 C38.38,23.06 38.37,22.19 38.25,21.33 C38.06,19.9 37.55,18.57 36.83,17.32 C35.86,15.64 34.57,14.26 32.93,13.22 C32.86,13.19 32.78,13.15 32.66,13.08 L32.66,13.08 Z"
                fill="#FFFFFF"/>
              <path
                d="M27.58,55.16 C12.37,55.16 0,42.79 0,27.58 C0,12.37 12.37,0 27.58,0 C42.79,0 55.16,12.37 55.16,27.58 C55.16,42.79 42.79,55.16 27.58,55.16 L27.58,55.16 Z M27.58,1.2 C13.03,1.2 1.2,13.03 1.2,27.58 C1.2,42.13 13.03,53.96 27.58,53.96 C42.13,53.96 53.96,42.13 53.96,27.58 C53.96,13.03 42.12,1.2 27.58,1.2 L27.58,1.2 Z"
                fill="#FFFFFF"/>
            </g>
          </svg>
          {!showScenes &&
          <svg className={"TipsArrow"} width="43px" height="54px" viewBox="0 0 43 54" version="1.1"
               xmlns="http://www.w3.org/2000/svg">
            <filter id="tipsArrowShadow" x="0" y="0" width="200%" height="200%">
              <feOffset result="offOut" in="SourceGraphic" dx="5" dy="5" />
              <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
               // filter={"url(#tipsArrowShadow)"}
            >
              <path d="M37,53.3 C36.4,53.9 35.5,54.1 34.5,53.7 C31.9,52.6 29.3,51.5 26.7,50.4 C24.8,49.6 23,48.8 21,48 C20.7,47.9 20.4,47.9 20.2,48 C15.7,49.9 11.3,51.8 6.8,53.7 C6.3,53.9 5.7,54.1 5.2,53.9 C4.1,53.5 3.5,52.5 3.7,51.4 C3.8,51.2 3.8,51 4,50.7 C8.9,40.7 13.8,30.7 18.6,20.6 C19.4,19.1 20.7,18.9 21.6,19.5 C22,19.7 22.3,20.1 22.5,20.6 C24.9,25.4 27.2,30.3 29.6,35.2 C32.1,40.4 34.6,45.6 37.2,50.8 C37.8,51.7 37.7,52.6 37,53.3 L37,53.3 Z"  fill="#63FFFF"/>
              <path d="M29.8,12.6 C32.9,15.9 34.2,19.7 33.4,24.1 C33.2,25.3 32.3,26.1 31.1,25.9 C30,25.8 29.2,24.7 29.4,23.5 C29.9,20.3 29,17.6 26.6,15.4 C22.4,11.5 15.9,12.4 12.8,17.3 C11.7,19.1 11.2,21.1 11.5,23.2 C11.5,23.5 11.6,23.8 11.6,24 C11.5,25.1 10.8,25.9 9.8,26 C8.7,26.1 7.8,25.4 7.7,24.3 C7.3,22.2 7.3,20 8.1,17.9 C9.8,13.2 13,10.3 17.8,9.2 C21.6,8.4 25.3,9.2 28.4,11.6 C29,11.9 29.5,12.3 29.8,12.6 L29.8,12.6 Z"  fill="#63FFFF">
                <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="0;1;0;1;0"/>
              </path>
              <path d="M38.8,32.5 C38.2,33.1 37.2,33.1 36.5,32.6 C35.9,32.1 35.8,31.3 36.2,30.3 C36.3,29.9 36.6,29.4 36.8,29 C37.2,28.2 37.5,27.4 37.8,26.6 C39.4,21 38.4,15.9 34.9,11.1 C31.5,6.6 26.8,4.2 21,4.1 C17,4 13.4,5.2 10.3,7.6 C9.6,8.2 9,8.7 8.5,9.3 C4.3,13.5 2.7,18.8 3.7,25 C3.9,26.6 4.5,28.4 5.6,30.2 C5.8,30.7 5.9,31 6,31.5 C5.9,31.9 5.9,32.2 5.6,32.4 C5.4,32.6 5.1,32.8 4.8,32.8 C4.2,33 3.3,32.7 3,32.1 C2.8,31.7 2.6,31.3 2.5,31 C2,30.1 1.5,29 1.2,28 C-0.8,21.5 0.1,15.4 3.7,10 C4.1,9.3 4.8,8.4 5.3,7.9 C5.4,7.8 5.7,7.5 5.7,7.4 C9.9,3.3 14.4,1.2 19.6,0.8 C24.6,0.4 29.1,1.7 33.3,4.6 C36.6,6.9 39,10 40.7,13.9 C42.8,19.1 42.8,24.4 40.6,29.9 C40.3,30.6 40,31.2 39.6,31.9 C39.1,32.2 38.9,32.3 38.8,32.5 L38.8,32.5 Z"  fill="#63FFFF">
                <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="1;0;1;0;1"/>
              </path>
            </g>
          </svg>
          }
        </div>
        <audio loop={true} autoPlay={true} ref={audioElement}>
          <source src="/music.mp3" type="audio/mpeg"/>
        </audio>
      </div>
    </BodyClassName>
  )
}



export default withSize({monitorHeight: true})(Home)



