import React  from "react";


export default ({className, style, onClick1, onClick2}) => {
  return <svg
    className={className}
    style={style}
    viewBox="0 0 814 1500"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
  >
    <defs>
      <filter id="star03Shadow" x="0" y="0" width="200%" height="200%">
        <feOffset result="offOut" in="SourceGraphic" dx="5" dy="5" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
      <radialGradient cx="50.3272729%" cy="49.5811281%" fx="50.3272729%" fy="49.5811281%" r="50.015614%"
                      id="star02RadialGradient-1">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3381431%" cy="49.5904558%" fx="50.3381431%" fy="49.5904558%" r="50.0192982%"
                      id="star02RadialGradient-2">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.293533%" cy="49.4914078%" fx="50.293533%" fy="49.4914078%" r="50.0136842%"
                      id="star02RadialGradient-3">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3587129%" cy="49.568816%" fx="50.3587129%" fy="49.568816%" r="50.0105263%"
                      id="star02RadialGradient-4">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3616252%" cy="49.5295205%" fx="50.3616252%" fy="49.5295205%" r="50.0084211%"
                      id="star02RadialGradient-5">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3266885%" cy="49.5993012%" fx="50.3266885%" fy="49.5993012%" r="50.0059649%"
                      id="star02RadialGradient-6">
        <stop stopColor="#FFFFFF" offset="3.48%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0.2782" offset="47.12%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="90%"/>
      </radialGradient>
      <radialGradient cx="50.3572361%" cy="49.5595701%" fx="50.3572361%" fy="49.5595701%" r="50.0140351%"
                      id="star02RadialGradient-7">
        <stop stopColor="#FFFFFF" offset="3.479043%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
      </radialGradient>
      <radialGradient cx="50.2384912%" cy="49.5971962%" fx="50.2384912%" fy="49.5971962%" r="50.0140351%"
                      id="star02RadialGradient-8">
        <stop stopColor="#FFFFFF" offset="3.479043%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
      </radialGradient>
      <radialGradient cx="50.3247354%" cy="49.5885035%" fx="50.3247354%" fy="49.5885035%" r="50.0140351%"
                      id="star02RadialGradient-9">
        <stop stopColor="#FFFFFF" offset="3.479043%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
      </radialGradient>
      <radialGradient cx="50.2563344%" cy="49.521596%" fx="50.2563344%" fy="49.521596%" r="50.0140351%"
                      id="star02RadialGradient-10">
        <stop stopColor="#FFFFFF" offset="3.479043%"/>
        <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
      </radialGradient>
    </defs>
    <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect  x="0" y="0" width="815" height="1500"/>
      <circle
        onClick={onClick1}
        fill="url(#star02RadialGradient-1)" cx="116" cy="361.9" r="28.5">
        <animate attributeName="r" begin="0s" dur="8s" repeatCount="indefinite" values="28.48;80;5;100;10;28.48"/>
        <animate attributeName="cx" begin="0s" dur="3s"
                 values="0;116"/>
        <animate attributeName="cy" begin="0s" dur="3s"
                 values="185;361"/>
      </circle>
      <circle
        onClick={onClick1}
        fill="url(#star02RadialGradient-2)" cx="267.3" cy="493.8" r="28.5">
        <animate attributeName="r" begin="0s" dur="7s" repeatCount="indefinite" values="28.48;100;20;60;20;28.48"/>
        <animate attributeName="cx" begin="0s" dur="3.5s"
                 values="0;267"/>
        <animate attributeName="cy" begin="0s" dur="3.5s"
                 values="185;493"/>
      </circle>
      <circle
        onClick={onClick1}
        fill="url(#star02RadialGradient-3)" cx="254.3" cy="590" r="28.5">
        <animate attributeName="r" begin="0s" dur="4s" repeatCount="indefinite" values="28.48;100;20;50;20;28.48"/>
        <animate attributeName="cx" begin="0s" dur="4s"
                 values="0;254"/>
        <animate attributeName="cy" begin="0s" dur="4s"
                 values="185;590"/>
      </circle>
      <circle
        onClick={onClick2}
        fill="url(#star02RadialGradient-4)" cx="373.6" cy="685.8" r="28.5">
        <animate attributeName="r" begin="0s" dur="10s" repeatCount="indefinite" values="28.48;100;28.48;50;40;28.48"/>
        <animate attributeName="cx" begin="0s" dur="3.1s"
                 values="0;373"/>
        <animate attributeName="cy" begin="0s" dur="3.1s"
                 values="185;685"/>
      </circle>
      <circle
        onClick={onClick2}
        fill="url(#star02RadialGradient-5)" cx="322" cy="785" r="28.5">
        <animate attributeName="r" begin="0s" dur="6s" repeatCount="indefinite" values="28.48;80;28.48;70;40;28.48"/>
        <animate attributeName="cx" begin="0s" dur="3s"
                 values="0;322"/>
        <animate attributeName="cy" begin="0s" dur="3s"
                 values="185;785"/>
      </circle>
      <circle
        onClick={onClick2}
        fill="url(#star02RadialGradient-6)" cx="430.6" cy="852.3" r="28.5">
        <animate attributeName="r" begin="0s" dur="10s" repeatCount="indefinite" values="28.48;100;10.48;50;10;28.48"/>
        <animate attributeName="cx" begin="0s" dur="3.3s"
                 values="0;430"/>
        <animate attributeName="cy" begin="0s" dur="3.3s"
                 values="185;852"/>
      </circle>
      <g
        className={"Tips"}
        onClick={onClick1}
        filter={"url(#star03Shadow)"}
      >
        <path d="M409.6,462.8 C410.5,462.8 411.2,463.3 411.6,464.3 C412.7,466.9 413.7,469.5 414.8,472.1 C415.6,474 416.3,475.9 417.1,477.8 C417.2,478.1 417.4,478.3 417.7,478.4 C422.2,480.2 426.7,482 431.2,483.9 C431.7,484.1 432.2,484.4 432.5,484.9 C433,485.9 432.7,487.1 431.8,487.7 C431.6,487.8 431.4,487.9 431.1,488 C420.6,491.6 410,495.2 399.5,498.9 C397.9,499.4 396.8,498.6 396.6,497.6 C396.5,497.2 396.5,496.7 396.7,496.2 C398.4,491.1 400.2,486 402,480.8 C403.9,475.3 405.8,469.9 407.6,464.4 C407.9,463.4 408.6,462.9 409.6,462.8 L409.6,462.8 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd"/>
        <path d="M385.9,496.7 C386,492.2 387.8,488.6 391.5,486 C392.5,485.3 393.7,485.4 394.4,486.4 C395.1,487.3 394.9,488.6 393.9,489.3 C391.3,491.2 390,493.8 390.1,497 C390.3,502.7 395.6,506.7 401.2,505.4 C403.3,504.9 405,503.8 406.3,502.1 C406.5,501.9 406.6,501.6 406.8,501.4 C407.6,500.7 408.7,500.6 409.5,501.3 C410.3,502 410.5,503.1 409.8,504 C408.6,505.8 407,507.3 405,508.2 C400.5,510.3 396.1,510.1 392,507.5 C388.7,505.4 386.7,502.2 386.2,498.3 C386,497.8 385.9,497.2 385.9,496.7 L385.9,496.7 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="0;1;0;1;0"/>
        </path>
        <path d="M393.6,476.3 C394.5,476.3 395.2,477 395.3,477.9 C395.4,478.7 394.9,479.3 393.9,479.7 C393.5,479.9 393,480.1 392.5,480.2 C391.7,480.5 390.9,480.8 390.1,481.2 C385,484 382.1,488.4 381.2,494.2 C380.4,499.8 382,504.8 386.1,509 C388.9,511.9 392.3,513.6 396.2,514.1 C397.1,514.2 397.9,514.2 398.7,514.2 C404.6,514.2 409.5,511.6 413.2,506.5 C414.2,505.2 415,503.5 415.5,501.5 C415.7,501 415.9,500.7 416.2,500.3 C416.5,500.1 416.8,499.9 417.1,499.9 C417.4,499.9 417.7,500 418,500.2 C418.6,500.5 419,501.3 418.8,502 C418.7,502.4 418.5,502.8 418.4,503.1 C418.1,504.1 417.7,505.2 417.2,506.1 C414,512.1 409.1,515.8 402.7,517.1 C401.9,517.3 400.8,517.4 400.1,517.5 C399.9,517.5 399.6,517.5 399.5,517.6 C393.6,517.5 389,515.8 385,512.4 C381.2,509.1 378.9,505 378,500 C377.3,496 377.8,492.1 379.4,488.2 C381.6,483 385.4,479.3 390.8,477 C391.5,476.7 392.2,476.5 392.9,476.3 C393.2,476.3 393.4,476.3 393.6,476.3 L393.6,476.3 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="1;0;1;0;1"/>
        </path>
      </g>
      <g
        className={"Tips"}
        onClick={onClick2}
        filter={"url(#star03Shadow)"}
      >
        <path d="M225.3,821.2 C224.4,821.2 223.7,820.7 223.3,819.7 C222.2,817.1 221.2,814.5 220.1,811.9 C219.3,810 218.6,808.1 217.8,806.2 C217.7,805.9 217.5,805.7 217.2,805.6 C212.7,803.8 208.2,802 203.7,800.1 C203.2,799.9 202.7,799.6 202.4,799.1 C201.9,798.1 202.2,796.9 203.1,796.3 C203.3,796.2 203.5,796.1 203.8,796 C214.3,792.4 224.9,788.8 235.4,785.1 C237,784.6 238.1,785.4 238.3,786.4 C238.4,786.8 238.4,787.3 238.2,787.8 C236.5,792.9 234.7,798 232.9,803.2 C231,808.7 229.1,814.1 227.3,819.6 C227,820.6 226.3,821.1 225.3,821.2 L225.3,821.2 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd"/>
        <path d="M249,787.3 C248.9,791.8 247.1,795.4 243.4,798 C242.4,798.7 241.2,798.6 240.5,797.6 C239.8,796.7 240,795.4 241,794.7 C243.6,792.8 244.9,790.2 244.8,787 C244.6,781.3 239.3,777.3 233.7,778.6 C231.6,779.1 229.9,780.2 228.6,781.9 C228.4,782.1 228.3,782.4 228.1,782.6 C227.3,783.3 226.2,783.4 225.4,782.7 C224.6,782 224.4,780.9 225.1,780 C226.3,778.2 227.9,776.7 229.9,775.8 C234.4,773.7 238.8,773.9 242.9,776.5 C246.2,778.6 248.2,781.8 248.7,785.7 C248.9,786.2 249,786.8 249,787.3 L249,787.3 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="0;1;0;1;0"/>
        </path>
        <path d="M241.3,807.7 C240.4,807.7 239.7,807 239.6,806.1 C239.5,805.3 240,804.7 241,804.3 C241.4,804.1 241.9,803.9 242.4,803.8 C243.2,803.5 244,803.2 244.8,802.8 C249.9,800 252.8,795.6 253.7,789.8 C254.5,784.2 252.9,779.2 248.8,775 C246,772.1 242.6,770.4 238.7,769.9 C237.8,769.8 237,769.8 236.2,769.8 C230.3,769.8 225.4,772.4 221.7,777.5 C220.7,778.8 219.9,780.5 219.4,782.5 C219.2,783 219,783.3 218.7,783.7 C218.4,783.9 218.1,784.1 217.8,784.1 C217.5,784.1 217.2,784 216.9,783.8 C216.3,783.5 215.9,782.7 216.1,782 C216.2,781.6 216.4,781.2 216.5,780.9 C216.8,779.9 217.2,778.8 217.7,777.9 C220.9,771.9 225.8,768.2 232.2,766.9 C233,766.7 234.1,766.6 234.8,766.5 C235,766.5 235.3,766.5 235.4,766.4 C241.3,766.5 245.9,768.2 249.9,771.6 C253.7,774.9 256,779 256.9,784 C257.6,788 257.1,791.9 255.5,795.8 C253.3,801 249.5,804.7 244.1,807 C243.4,807.3 242.7,807.5 242,807.7 C241.7,807.7 241.5,807.7 241.3,807.7 L241.3,807.7 Z"  stroke="none" fill="#63FFFF" fillRule="evenodd">
          <animate attributeName="opacity" begin="0s" dur="4s" repeatCount="indefinite" values="1;0;1;0;1"/>
        </path>
      </g>
    </g>
  </svg>
}


